<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Special Winner</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="searchVal"
        label="Search"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="pokerModeSelect"
        :items="pokerMode"
        item-value="key"
        item-text="value"
        @change="initialize"
        hide-details
        label="Poker Mode"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="minBuySelect"
        :items="$store.state.specialWinner.minBuys"
        item-value="key"
        item-text="value"
        @change="initialize"
        hide-details
        label="Min Buy"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="winAmountSelect"
        :items="$store.state.specialWinner.winAmount"
        item-value="key"
        item-text="value"
        @change="initialize"
        hide-details
        label="WinAmount"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="all"
      :page.sync="page"
      @page-count="pageCount = $event"
      :options.sync="options"
      hide-default-footer
      :items-per-page="itemsPerPage"
      :server-items-length="$store.state.specialWinner.all.totalPage"
      class="elevation-1"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [50, 100, 500, 1000, 2000],
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      }"
      :item-key="
        new Date().getTime() + parseInt(Math.random() * 100).toString()
      "
    >
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
        <v-icon small @click="deleteItem(item)">delete</v-icon>
      </template>

      <template v-slot:item.w="{ item }">
        {{ item.w | formatMoney }}
      </template>
      <template v-slot:item.b="{ item }">
        {{ item.b | formatMoney }}
      </template>
      <template v-slot:item.t="{ item }">
        {{ new Date(item.t * 1000) | formatDateSS }}
      </template>
      <template v-slot:item.m="{ item }">
        <template>
          {{
            pokerMode.find((a) => a.key == item.m)
              ? pokerMode.find((a) => a.key == item.m).value
              : item.m
          }}
        </template>
      </template>
      <template v-slot:item.i="{ item }">
        <user-detail-card
          :userId="item.i"
          :pictureUrl="item.p"
          :userName="item.u"
          :level="item.l"
          :flag="item.f"
        />
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :total-visible="20"
        :length="$store.state.specialWinner.all.totalPage"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import UserDetailCard from "../../../components/Users/UserDetailCard.vue";
import pokerMode from "@/lib/pokerMode";

export default {
  components: { UserDetailCard },
  name: "SpecialWinnerListCard",
  props: {
    search: {
      type: String,
    },
  },
  data() {
    return {
      all: [],
      pokerMode: pokerMode,
      options: {},
      pokerModeSelect: { value: "Any", key: -1 },
      minBuySelect: { value: "All", key: -1 },
      winAmountSelect: { value: "All", key: -1 },
      page: 1,
      pageCount: 0,
      itemsPerPage: 100,
      searchVal: {},
      headers: [
        { text: "User", value: "i" },
        { text: "Time", value: "t" },
        { text: "cards", value: "c" },
        { text: "handId", value: "h" },
        { text: "isJackpot", value: "ij" },
        { text: "isStraightFlush", value: "is" },
        { text: "isTrioMini", value: "im" },
        { text: "minBuy", value: "b" },
        { text: "mode", value: "m" },
        { text: "winAmount", value: "w" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    editItem() {},
    async initialize() {
      // eslint-disable-next-line no-unused-vars
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      await this.$store.dispatch("specialWinner/getAll", {
        search: this.searchVal,
        page: page - 1,
        total: itemsPerPage,
        options: this.options,
        pokerMode: this.pokerModeSelect.key,
        minBuyIn: this.minBuySelect.key,
        winAmount: this.winAmountSelect.key,
      });
      this.all = this.$store.state.specialWinner.all.data;
    },
  },

  async mounted() {
    this.searchVal = this.search;
  },
};
</script>
